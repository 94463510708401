import Css from "./style.module.scss";

import Constants from "const/Constants";
import React from "react";
import TaskContent from "nlib/common/TaskContent";
import TaskForm from "nlib/common/TaskForm";
import usePreventBodyScroll from "hooks/usePreventBodyScroll";

const ListItemDetails = ({ taskId, onClose }) => {
  usePreventBodyScroll();

  return taskId === Constants.NEW_ENTITY_ID
    ? <TaskForm className={Css.listItemDetails} onCancel={onClose} />
    : (
      <TaskContent
        mobile
        className={Css.listItemDetails}
        key={taskId}
        taskId={taskId}
        onClose={onClose} />
    );
};

export default React.memo(ListItemDetails);

import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, DebounceInput, Modal } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import FilterByUser from "nlib/pages/TasksPage/lib/FilterByUser";
import React, { useCallback, useState } from "react";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const Filters = ({ active }) => {
  const { uiTexts } = useSelector(getTextsData);

  const [envVars, setEnvVars] = useEnvVars();

  const [textInputValue, setTextInputValue] = useState(envVars.text);

  const [searchFocused, setSearchFocused] = useState(false);

  const [opened, setOpened] = useState(false);

  const hasFilters = !![envVars.fromDate, envVars.toDate, envVars.targetUser].find(Boolean);

  const handleSearchFocus = useCallback(() => {
    setSearchFocused(true);
  }, []);

  const handleSearchBlur = useCallback(() => {
    setSearchFocused(false);
  }, []);

  const handleTextInputComplete = useCallback((value) => {
    setEnvVars({ text: value });
  }, [setEnvVars]);

  const handleDropDownToggle = useCallback(() => {
    setOpened((prev) => !prev);
  }, []);

  const handleResetClick = useCallback(() => {
    setEnvVars({ fromDate: null, toDate: null, targetUser: null });
    setOpened(false);
  }, [setEnvVars]);

  const handleUserSelect = useCallback(() => {
    setOpened(false);
  }, []);

  return (
    <>
      <div className={classNames(Css.filters, active && Css.active)}>
        <div className={classNames(Css.container, CommonCss.mobileContainer)}>
          <div className={Css.title}>
            <span>{uiTexts.tasks}</span>
            <DebounceInput
              cleanable
              className={classNames(Css.search, (!!textInputValue || searchFocused) && Css.focused)}
              active={!!envVars.text}
              placeholder={uiTexts.searchTask}
              iconBefore={Icons.MagnifyingGlass}
              value={textInputValue}
              onFocus={handleSearchFocus}
              onBlur={handleSearchBlur}
              onChange={setTextInputValue}
              onInputComplete={handleTextInputComplete} />
          </div>
          <Button
            outline
            primary={hasFilters}
            className={Css.moreFilters}
            onClick={handleDropDownToggle}>
            <Icons.Funnel />
          </Button>
        </div>
      </div>
      {opened && (
        <Modal
          title={uiTexts.filters}
          className={Css.filtersModal}
          onClose={handleDropDownToggle}>
          <div className={Css.list}>
            <div className={Css.listItem}>
              <FilterByUser
                outline
                className={Css.filterByUser}
                onSelect={handleUserSelect} />
            </div>
          </div>
          <div className={Css.buttons}>
            <Button
              primary outline
              disabled={!hasFilters}
              className={Css.button}
              onClick={handleResetClick}>
              <Icons.X /> {uiTexts.resetAll}
            </Button>
            <Button
              light
              className={Css.button}
              onClick={handleDropDownToggle}>
              {uiTexts.close}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default React.memo(Filters);
